<template>
  <div id="wrapper">
    <nl-header v-if="connectWallet" />
    <nl-header-no-wallet v-if="!connectWallet" />
    <notifications position="top center" width="30%" />
    <router-view />
    <nl-footer />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "app",
  data() {
    return {
      connectWallet: process.env.VUE_APP_CONNECTWALLET === "true",
    };
  },
  components: {
    nlHeader: defineAsyncComponent(() =>
      import("@/components/common/Header.vue")
    ),
    nlHeaderNoWallet: defineAsyncComponent(() =>
      import("@/components/common/HeaderNoWallet.vue")
    ),
    nlFooter: Footer,
  },
};
</script>

<style lang="scss">
@import "assets/scss/main.scss";
</style>
