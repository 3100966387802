import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import Home from "@/views/Home.vue";
// import Client from "@/views/Raffle.vue";
import Landing from "@/views/Landing.vue";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   component: Home,
  // },
  // {
  //   path: "/raffle/:address",
  //   component: Client,
  // },
  // {
  //   path: "/raffle/latest",
  //   redirect: "/raffle/0x322813fd9a801c5507c9de605d63cea4f2ce6c44", // TODO: remove for prod build
  // },
  {
    path: "/",
    component: Landing,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
