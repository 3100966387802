<template>
  <!-- how it works section -->
  <section
    class="work-section"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg-intro.svg') + ')',
    }"
  >
    <div class="container">
      <div class="heading-area text-center">
        <h2>HOW IT WORKS</h2>
      </div>
      <div class="process-holder">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="text">
              <span class="count">1.</span>
              <h3>CONNECT WALLET</h3>
              <p>Connect your MetaMask wallet in seconds</p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="text">
              <span class="count">2.</span>
              <h3>BUY ENTRIES</h3>
              <br />
              <p>
                Buy as many entries you like to increase your chance of winning
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="text">
              <span class="count">3.</span>
              <h3>AUTOMATIC PRIZE DRAW</h3>

              <p>
                After buying the tickets you enter the draw automatically. All
                money is held in escrow until either the winner is drawn and the
                prize awarded.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowItWorks",
};
</script>

<style scoped></style>
