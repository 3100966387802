<template>
  <!-- footer of the page -->
  <footer id="footer">
    <div class="container">
      <div class="logo">
        <a href="#">
          <img src="@/assets/images/logo.svg" alt="" />
        </a>
      </div>
      <div class="footer-holder">
        <nav class="social-links">
          <ul>
            <li>
              <a href="https://twitter.com" target="_blank">
                <img
                  src="@/assets/images/logo-twitter.svg"
                  alt=""
                  width="20"
                  height="20"
                />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/WJnzbszbm7" target="_blank">
                <img
                  src="@/assets/images/logo-discord.svg"
                  alt=""
                  width="20"
                  height="20"
                />
              </a>
            </li>
          </ul>
        </nav>
        <address>Made with ❤ in Amsterdam & London</address>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
