import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_MidSection = _resolveComponent("MidSection")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Banner),
    _createVNode(_component_MidSection),
    _createVNode(_component_HowItWorks)
  ], 64))
}