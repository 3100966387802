<template>
  <!-- upload file details -->
  <section
    class="file-details"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg-intro.svg') + ')',
    }"
  >
    <div class="container">
      <div class="head">
        <h2>CROWD DRIVEN NFT OWNERSHIP & LOTTERIES</h2>
      </div>
      <div class="info-cards">
        <div class="row">
          <div class="col-12 col-lg-4">
            <p>
              Nifty Luck App powered by smart contracts on EVM based blockchains
            </p>
          </div>
          <div class="col-12 col-lg-4">
            <p>
              All raffle money held in escrow with a guaranteed reserve price
            </p>
          </div>
          <div class="col-12 col-lg-4">
            <p>Transparent winnings / Automatic prize draw</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    class="file-details nft"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg-intro.svg') + ')',
    }"
  >
    <div class="container">
      <div class="head">
        <h2>TRANSPARENT RAFFLES ON THE BLOCK CHAIN</h2>
      </div>
      <div class="img-area">
        <div class="img-holder">
          <img src="../../assets/images/img1.jpg" alt="" />
        </div>
      </div>
      <p>
        You can confidently buy entries into the raffle to become a crypto
        whale, the equivalent of a FIAT millionaire
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "MidSection",
};
</script>

<style scoped></style>
