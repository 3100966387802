<template>
  <!-- banner area -->
  <section
    class="banner"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg-banner.png') + ')',
    }"
  >
    <div class="container">
      <div class="row alignitems-end">
        <div class="col-12 col-md-7">
          <h1>WE ARE BUILDING THE FIRST NFT RAFFLE dAPP</h1>
          <p>
            Join our Discord community of NFT enthusiasts, makers & collectors.
          </p>
          <div class="btn-holder">
            <a
              href="https://discord.gg/WJnzbszbm7"
              target="_blank"
              class="btn btn-primary"
              >JOIN DISCORD</a
            >
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="card">
            <div class="img-holder">
              <img src="../../assets/images/img.jpg" alt="" />
            </div>
            <div class="text">
              <span
                >* The displayed NFT is for illustrative purposes only and does
                not represent actual ownership</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style scoped></style>
