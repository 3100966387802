import { createApp } from "vue";
import { createPinia } from "pinia";
import VueLazyLoad from "vue3-lazyload";
import Notifications from "@kyvg/vue3-notification";

import App from "./App.vue";
import router from "./router";

const store = createPinia();

createApp(App)
  .use(router)
  .use(store)
  .use(VueLazyLoad)
  .use(Notifications)
  .mount("#app");
