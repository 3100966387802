
import { Options, Vue } from "vue-class-component";
import Banner from "@/components/landing/Banner.vue";
import MidSection from "@/components/landing/MidSection.vue";
import HowItWorks from "@/components/landing/HowItWorks.vue";

@Options({
  name: "Landing",
  components: {
    Banner,
    HowItWorks,
    MidSection,
  },
})
export default class Landing extends Vue {}
